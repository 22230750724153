import React, { useEffect, useState } from 'react'
import './Dashboard.css'
import AppNav from '../../../components/AppNav.js'
import Index from './Index.js';
import Profile from './Profile';
import Calendar from './Calendar';
import Inbox from './Inbox'
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import Search from './Search/Search';
import Memorial from './Memorial';
import Album from './Album';
import Chronicle from './Chronicle';
import Setting from './Setting/Index';
import UserProfile from './Miscellaneous/UserProfile';
import axios from '../../../axios'
import Post from './Miscellaneous/Post';
import { toast } from 'react-toastify';
import Notification from './Notification';
import AI from './AI';
import translations from '../../../locales/translations.json';



function Dashboard() {

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };
  const savedLanguage = getCookie('selectedLanguage');
  const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined;

  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN');
  const [t, setT] = useState(translations[currentLanguage]);

  useEffect(() => {
    // If no saved language, set English by default
    if (!savedLanguage) {
      document.cookie = `selectedLanguage=en; path=/`;
    }

    // Update translation based on the current language
    setT(translations[currentLanguage]);
  }, [currentLanguage, savedLanguage]);



  const token = localStorage.getItem('token')
  const email = localStorage.getItem('email')
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchResult, setSearchResult] = useState(null)
  // user profile modal
  const [showUserProfile, setShowUserProfile] = useState(false)
  // post modal
  const [showPost, setShowPost] = useState(false)
  // user profile modal data
  const [userProfileData, setUserProfileData] = useState({})
  // post modal data
  const [postData, setPostData] = useState({})
  const [postID, setPostID] = useState(null)
  const [postBool, setPostBool] = useState({ cmntForm: false, showComments: false, showLikes: false })
  // logged In user Data
  const [userData, setUserData] = useState(null)

  const [followers, setFollowers] = useState(null)
  const [followings, setFollowings] = useState(null)
  const [requests, setRequests] = useState(null)

  const [feeds, setFeeds] = useState(null)

  const [notification, setNotification] = useState(null)
  const [unreadNotification, setUnreadNotification] = useState(null)

  const getSearchResult = (query = searchParams.get('query')) => {
    if (query)
      axios.post('/user/searchUsers', { token: token, query })
        .then(res => { setSearchResult(res.data.data) })
        .catch(e => console.error(e))
    else
      axios.post('/user/searchUsers', { token: token })
        .then(res => { setSearchResult(res.data.data) })
        .catch(e => console.error(e))
  }

  const postComment = (postID, comment, val = null, setVal = null) => {
    axios.post('/user/comment', { token: token, post: postID, comment: comment })
      .then(res => {
        // loadFeeds()
        // refreshUser()
        if (val && setVal) setVal([{
          name: userData.user.first_name + " " + userData.user.last_name,
          comment: comment,
          created_at: "now",
          email: userData.user.email,
          interaction_public_id: res.data.interaction_public_id,
          liked: false,
          username: userData.user.username
        }, ...val])

        setFeeds(feeds.map(feed => {
          if (feed.public_id === postID) {
            feed.total_comments += 1
            return feed
          } else return feed
        }))
        const p = userData.posts.map(post => {
          if (post.public_id === postID) {
            post.total_comments += 1
            return post
          } else return post
        })
        setUserData({ ...userData, posts: p })
      })
      .catch(e => console.error(e))
  }

  const deleteComment = (postID) => {
    const f = feeds.map(feed => {
      if (feed.public_id === postID) {
        feed.total_comments -= 1
        return feed
      } else return feed
    })

    setFeeds(f)

    const p = userData.posts.map(post => {
      if (post.public_id === postID) {
        post.total_comments -= 1
        return post
      } else return post
    })
    setUserData({ ...userData, posts: p })
  }

  const loadFeeds = () => {
    var current_url = window.location.href;
    var demo_account = false
    if (current_url.includes("demo_account")) {
      demo_account = true
    }
    const body = { demo_user: demo_account, token: token }
    axios.post("/user/feed", body)
      .then(res => setFeeds(res.data))
      .catch(e => {
        console.error(e)
        toast.error("Error while fetching Feeds")
      })
  }

  const refreshFFR = () => {
    axios.post('/user/getFollowing', { token: token })
      .then(res => { setFollowings(res.data.connections) })
      .catch(e => console.error(e))

    axios.post('/user/getFollowers', { token: token })
      .then(res => { setFollowers(res.data.connections) })
      .catch(e => console.error(e))

    axios.post('/user/getFollowRequests', { token: token })
      .then(res => { setRequests(res.data.connections) })
      .catch(e => console.error(e))
  }

  const sendRequest = (user_email) => {
    axios.post('/user/createConnection', { token: token, user_email: user_email })
      .then((res) => {
        setSearchResult(searchResult.map(user => {
          if (user.email === user_email) {
            user.connection = 'requested'
            return user
          } else return user
        }))
      })
      .catch(err => console.error(err))
  }

  const acceptRequest = (user_email) => {
    axios.post('/user/acceptConnectionRequest', { token: token, user_email })
      .then((res) => {
        let v
        requests.map(req => {
          if (req.email === user_email)
            v = req
        })
        setFollowers([v, ...followers])
        setRequests(requests.filter(req => req.email !== user_email))
      })
      .catch(err => console.error(err))
  }

  const deleteRequest = (user_email, send = false) => {
    axios.post('/user/cancelConnectionRequest', { token: token, user_email: user_email })
      .then(() => {
        if (send)
          setSearchResult(searchResult.map(user => {
            if (user.email === user_email) {
              user.connection = 'none'
              return user
            } else return user
          }))
        else
          setRequests(requests.filter(request => request.email !== user_email))
      })
      .catch(err => console.error(err))
  }

  const unFollow = (user_email) => {
    const alert = window.confirm("Do You want to UnFollow?")
    if (alert)
      axios.post("/user/removeFollowing", { token: token, user_email: user_email })
        .then((res) => {
          setFollowings(followings.filter(following => following.email !== user_email))
          setSearchResult(searchResult.map(user => {
            if (user.email === user_email) {
              user.connection = 'none'
              return user
            } else return user
          }))
        })
        .catch(e => console.error(e))
  }

  const deleteFollower = (user_email) => {
    axios.post('/user/removeFollower', { token: token, user_email: user_email })
      .then(() => {
        setFollowers(followers.filter(follower => follower.email !== user_email))
      })
      .catch(err => console.error(err))
  }

  const refreshUser = () => {
    axios.post('/user/getUserAllPosts', { token: token, user_email: email })
      .then(res => { setUserData(res.data) })
      .catch(err => console.error(err))
  }

  const showProfileModel = (username = null, email = null) => {
    if (email) {
      axios.post('/user/getUserAllPosts', { token: localStorage.getItem('token'), user_email: email })
        .then(res => { setUserProfileData(res.data); setShowUserProfile(true) })
        .catch(err => console.error(err))
    }
  }

  const likePost = (postID, val = null, setVal = null) => {
    axios.post('/user/like', { token: localStorage.getItem('token'), post: postID })
      .then(res => {
        setFeeds(feeds.map(feed => {
          if (feed.public_id === postID) {
            feed.liked = true
            feed.total_likes += 1
            return feed
          } else return feed
        }))
        const p = userData.posts.map(post => {
          if (post.public_id === postID) {
            post.liked = true
            post.total_likes += 1
            return post
          } else return post
        })
        setUserData({ ...userData, posts: p })
        if (val && setVal) setVal([{
          name: userData.user.first_name + " " + userData.user.last_name,
          email: userData.user.email
        }, ...val])
      })
      .catch(e => console.error(e))
  }

  const getNotification = () => {
    axios.post('/user/getNotifications', { token: token })
      .then(res => { setNotification(res.data.notification_data); })
      .catch(e => console.error(e))
  }
  const getUnreadNotification = () => {
    axios.post('/user/getUnreadNotifications', { token: token })
      .then(res => { setUnreadNotification(res.data.notification_data); })
      .catch(e => console.error(e))
  }

  const markNotification = (interactionID) => {
    axios.post('/user/markAsRead', { token: token, interaction_public_id: interactionID })
      .then((res) => {
        setNotification(notification.map(notify => {
          if (notify.interaction == interactionID) {
            notify.read = true
            return notify
          } else return notify
        }))
        setUnreadNotification(unreadNotification.filter(notify => notify.interaction !== interactionID))
      })
      .catch(e => console.error(e))
  }
  const deleteNotification = (interactionID) => {
    axios.post('/user/deleteNotification', { token: token, interaction_public_id: interactionID })
      .then(() => {
        // const n=notification.filter(notify=>notify.interaction!==interactionID)
        setNotification(notification.filter(notify => notify.interaction !== interactionID))
        setUnreadNotification(unreadNotification.filter(notify => notify.interaction !== interactionID))
      })
      .catch(e => console.error(e))
  }
  const clearNotification = () => {
    axios.post('/user/deleteAllNotifications', { token: token })
      .then(() => {
        setNotification([])
        setUnreadNotification([])
      })
      .catch(e => console.error(e))
  }

  const unLikePost = (postID, val = null, setVal = null) => {
    axios.post('/user/unlike', { token: localStorage.getItem('token'), post: postID })
      .then(res => {
        setFeeds(feeds.map(feed => {
          if (feed.public_id === postID) {
            feed.liked = false
            feed.total_likes -= 1
            return feed
          } else return feed
        }))
        const p = userData.posts.map(post => {
          if (post.public_id === postID) {
            post.liked = false
            post.total_likes -= 1
            return post
          } else return post
        })
        setUserData({ ...userData, posts: p })
        if (val && setVal)
          setVal(val.filter(l => l.email !== userData.user.email))
      })
      .catch(e => console.error(e))
  }

  const showPostModel = (post, postID, cmntForm = false, showLikes = false, showComments = false) => {
    setPostData(post)
    setPostID(postID)
    setPostBool({ cmntForm: cmntForm, showComments: showComments, showLikes: showLikes })
    setShowPost(true)
  }

  const navigate = useNavigate()
  useEffect(() => {
    var current_url = window.location.href;
    if (!current_url.includes("demo_account")) {
      const token = localStorage.getItem('token')
      if (token) {
        axios.post('/user/login', { token_value: token })
          .then(() => {
            refreshUser()
            refreshFFR()
            loadFeeds()
            getSearchResult()
            getNotification()
            getUnreadNotification()
          })
          .catch(() => {
            localStorage.clear()
            navigate('/login')
          })
      } else { navigate('/login') }
    }
  }, [])

  return (userData && followers && followings && requests && feeds && searchResult && notification && unreadNotification ?
    <div className='dashboard'>
      <AppNav getSearchResult={getSearchResult} showProfileModel={showProfileModel} unreadNotification={unreadNotification.length} userData={userData} />
      <Routes>
        <Route path='/' element={<Search sendRequest={sendRequest} acceptRequest={acceptRequest} deleteRequest={deleteRequest} unFollow={unFollow} deleteFollower={deleteFollower} searchResult={searchResult} userData={userData} followers={followers} followings={followings} requests={requests} showProfileModel={showProfileModel} />} exact />
        <Route path="/home" element={<Index />} exact />
        <Route path="/profile" element={<Profile feeds={feeds} setFeeds={setFeeds} loadFeeds={loadFeeds} userData={userData} likePost={likePost} unLikePost={unLikePost} showPostModel={showPostModel} followersCount={followers.length} />} exact />
        <Route path="/calender" element={<Calendar />} exact />
        <Route path='/inbox' element={<Inbox user={userData}/>} exact />
        <Route path='/memorial' element={<Memorial />} exact />
        <Route path='/chronicle' element={<Chronicle />} exact />
        <Route path='/album' element={<Album />} exact />
        <Route path='/setting' element={<Setting acceptRequest={acceptRequest} likePost={likePost} unLikePost={unLikePost} showPostModel={showPostModel} followers={followers} followings={followers} requests={requests} refreshFFR={refreshFFR} userData={userData} refreshUser={refreshUser} showProfileModel={showProfileModel} />} exact />
        <Route path='/notification' element={<Notification showPostModel={showPostModel} clearNotification={clearNotification} deleteNotification={deleteNotification} markNotification={markNotification} unreadNotification={unreadNotification} notification={notification} getUnreadNotification={getUnreadNotification} getNotification={getNotification} />} exact />
        <Route path='/ai' element={<AI userData={userData} />} />
      </Routes>
      {showUserProfile && <UserProfile setShowUserProfile={setShowUserProfile} userProfileData={userProfileData} />}
      {showPost && <Post user={userData.user} deleteComment={deleteComment} postComment={postComment} postBool={postBool} setShowPost={setShowPost} showPostModel={showPostModel} likePost={likePost} unLikePost={unLikePost} postData={postData} postID={postID} />}
    </div> : <div className='loading'>
      <i className='bx bx-loader-alt bx-spin' ></i><p>{t.loading}...</p>
    </div>

  )

}

export default Dashboard
